<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
document.title = "Tspace Survey";

export default {};
</script>

<style>
@font-face {
  font-family: "CHANG Sans REG";
  src: url("./assets/changsansregvxpc.ttf");
  font-weight: normal;
  font-style: normal;
}

html * {
  font-family: "CHANG Sans REG" !important;
  font-size: 18px;
}

html,
body {
  height: 100%;
}

#app {
  height: 100%;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
}

@media (max-width: 600px) {
  html * {
    font-size: 16px;
  }
}

@media (max-width: 1366px) {
  .container {
    width: 100% !important;
  }
}
</style>
